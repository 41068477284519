import React, { useEffect } from "react"

import Layout from "../components/layout"
import "../components/index/styles.scss"
import { CuartelesDeLaFinca } from "../components/index/cuartelesDeLaFInca"

import { FormattedMessage } from "react-intl";
import SimpleLocalize from "../components/SimpleLocalize";


import img01 from "../images/home-01.jpg"
import logoHome from "../images/logo-home.png"
import valleDeUco from "../images/index/valle-de-uco.jpeg"

import map_ubicacion from "../images/index/map_ubicacion.jpg"
import point_map_pasaje_altamira from "../images/index/point_map_pasaje_altamira.png"
import point_map_la_celia from "../images/index/point_map_la_celia.png"
import point_map_eugenio_bustos from "../images/index/point_map_eugenio_bustos.png"
import point_map_la_consulta from "../images/index/point_map_la_consulta.png"

import { Helmet } from "react-helmet"

const IndexPage = (props) => {
	let camino = '../..'
	let poslang = '_en';

	useEffect(() => {
		var viewport = document.getElementsByTagName('body')[0];
		const checkPos = (e) => {
			var a = viewport.scrollTop;
			var b = viewport.scrollHeight - viewport.clientHeight;
			var c = a / b;
			console.log(c)
			if (c === 0.25 && document.querySelector("#imagen_01")) {
				document.querySelector("#imagen_01").classList.add("image_01_animate");
				document.querySelector("#imagen_02").classList.add("image_02_animate");
				document.querySelector("#imagen_03").classList.add("image_03_animate");
			}
			if (c === 0.4) {
				var dotMap = document.querySelectorAll(".dotMap");
				dotMap.forEach(function (userItem) {
					userItem.classList.add("dotanimate");
				});
			}
		}
		viewport.addEventListener('scroll', checkPos, false);
	}, [])

	return (
		<SimpleLocalize {...props}>
			<Layout page='index' {...props}>
				<Helmet>
					<body />
				</Helmet>
				<section className="py-0 text-center position-relative min-vh-100" id="seccion1">
					<div className="bg-holder header-video" style={{ backgroundImage: "url(" + img01 + ")" }}>
						<div className="video-wrapper position-absolute w-100 h-100">
							<iframe
								title="video introduction"
								frameBorder="0"
								height="100%"
								width="100%"
								src="https://youtube.com/embed/5UFZX2iFWr0?autoplay=1&controls=0&showinfo=0&autohide=1&rel=0&loop=1&mute=1&playlist=5UFZX2iFWr0&modestbranding=1"
							>
							</iframe>
						</div>
						<div className="min-vh-100 content-wrapper d-flex flex-column justify-content-center text-white container">
							<div style={{ "width": "100%", "height": "100vh", "background": "transparent", "position": "absolute", "zIndex": "1", "left": "0" }}></div>
							<div style={{ "zIndex": "2" }}> <img src={logoHome} alt="" className="logo-home" /></div>

							<p className="col-8 offset-2 text-center" style={{ "zIndex": "2" }}>
								<FormattedMessage id="terrunio-unico" defaultMessage="terrunio-unico" />
							</p>
						</div>
					</div>
				</section>
				<section className="py-0 text-center position-relative min-vh-100" id="seccion2">
					<div className="bg-holder" style={{ backgroundImage: "url(" + valleDeUco + ")" }}>
						<div className="inside-bg-holder container-fluid">
							<div id="imagen_01">
								<h4><FormattedMessage id="valle-de-uco" defaultMessage="valle-de-uco" /></h4>
								<div className="paragraph-wrapper">
									<p><FormattedMessage id="valle-de-uco-es-el-valle" defaultMessage="valle-de-uco-es-el-valle" /></p>
									<p><FormattedMessage id="diferentes-altitudes" defaultMessage="diferentes-altitudes" /></p>
								</div>
								{props.pageContext.language === 'en' ? <><img src={`${camino}/lafinca/01_valledeuco_a_en.png`} alt="" className="mw-100" /></> : <><img src={`${camino}/lafinca/01_valledeuco_a.png`} alt="" className="mw-100" /></>}

							</div>
							<div id="imagen_02">
								<h4>Mendoza</h4>
								<img src={`${camino}/lafinca/01_valledeuco_b.png`} alt="" className="mw-100" />
							</div>
							<div id="imagen_03">
								<h4>Argentina</h4>
								<img src={`${camino}/lafinca/01_valledeuco_c.png`} alt="" className="mw-100" />
							</div>

						</div>
					</div>
				</section>
				<section className="py-0 text-center position-relative min-vh-100" id="seccion3">
					<div className="bg-holder no-top">
						<div className="container content-wrapper d-flex align-items-center">
							<div className="row ">
								<div className="col-md-4 text-start pt-0 ps-4 texto-con-scroll">
									<h1 className="title-2 mt-4" style={{ "maxWidth": "100%" }}>
										<FormattedMessage id="suelos-unicos" defaultMessage="suelos-unicos" />
									</h1>
									<hr />
									<p className="mt-4">
										<FormattedMessage id="finca-la-celia-se-encuentra-ubicada" defaultMessage="finca-la-celia-se-encuentra-ubicada" /><br />
										<FormattedMessage id="dando-como-resultado" defaultMessage="dando-como-resultado" />
									</p>
									<ul>
										<li><FormattedMessage id="suelo-tipo-paraje-altamira" defaultMessage="suelo-tipo-paraje-altamira" /></li>
										<li><FormattedMessage id="suelo-tipo-la-Consulta" defaultMessage="suelo-tipo-la-Consulta" /></li>
										<li><FormattedMessage id="suelo-tipo-eugenio-bustos" defaultMessage="suelo-tipo-eugenio-bustos" /></li>
									</ul>
									<p><FormattedMessage id="datos-generales-de-nuestra-finca" defaultMessage="datos-generales-de-nuestra-finca" /></p>
									<ul>
										<li><FormattedMessage id="tres-ochenta-hectareas-implantadas" defaultMessage="tres-ochenta-hectareas-implantadas" /></li>
										<li><FormattedMessage id="cinco-cinco-Malbec" defaultMessage="cinco-cinco-Malbec" /></li>
										<li><FormattedMessage id="trece-cabernet-sauvignon" defaultMessage="trece-cabernet-sauvignon" /></li>
										<li><FormattedMessage id="trece-cabernet-franc" defaultMessage="trece-cabernet-franc" /></li>
										<li><FormattedMessage id="altitud-nueve-ochenta" defaultMessage="altitud-nueve-ochenta" /></li>
										<li><FormattedMessage id="riego-100-por-cien-presurizado" defaultMessage="riego-100-por-cien-presurizado" /></li>
										<li><FormattedMessage id="edad-promedio-de-viniedos" defaultMessage="edad-promedio-de-viniedos" /></li>
									</ul>

								</div>
								<div className="col-md-8 position-relative">
									<div className="position-relative">
										<img src={map_ubicacion} alt="" className="img-fluid" />
										<ul id="map" className="position-absolute top-0" >
											<li title="pasaje Altamira" style={{ top: "36%", left: "40%" }} className="dotMap">
												<img src={point_map_pasaje_altamira} style={{ top: "-85px", left: "-134px" }} alt="" />
											</li>
											<li title="La Celia" style={{ top: "84%", left: "27%" }} className="dotMap">
												<img src={point_map_la_celia} style={{ top: "-85px", left: "-24px" }} alt="" />
											</li>
											<li title="eugenio Bustos" style={{ top: "89%", left: "69%" }} className="dotMap">
												<img src={point_map_eugenio_bustos} style={{ top: "-71px", left: "-87px" }} alt="" />
											</li>
											<li title="la consulta" style={{ top: "41%", left: "87%" }} className="dotMap">
												<img src={point_map_la_consulta} style={{ top: "-71px", left: "-87px" }} alt="" />
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				{/*<section className="py-0 text-center position-relative min-vh-100">
					<CuartelesDeLaFinca {...props} />
				</section>*/}
				<section className="py-0 text-center position-relative min-vh-100">
					<div className="bg-holder no-top">
						<iframe src={`https://terroir.laceliawines.com/tour/valledeuco.html?lang=${props.pageContext.language}`} title="Valle de UCO" className="w-100 h-100">
							<p>Your browser does not support iframes.</p>
						</iframe>
					</div>
				</section>

			</Layout></SimpleLocalize>)
}

export default IndexPage
